import XDate from 'xdate';
import {history} from '../create/history';
import {appsignal} from './global';

class DeprecationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'DeprecationError';
  }
}

export const logIfNotDeprecated = (idString) => {
  // Call this function in a file suspected of being unused & safe to delete from the repo.
  // Throw an error if hit, so we can easily catch it in AppSignal & by the unique idString,
  // can see what's still in use & follow up with further debugging.
  try {
    throw new DeprecationError(idString);
  } catch (error) {
    appsignal.sendError(error);
  }
};

export function pluralizeWord(label, count) {
  let modifiedLabel;
  let suffix;
  const lastLetter = label.slice(label.length - 1, label.length);
  if (lastLetter === 'y') {
    suffix = count === 1 ? 'y' : 'ies';
    modifiedLabel = label.slice(0, label.length - 1) + suffix;
  } else {
    suffix = count === 1 ? '' : 's';
    modifiedLabel = label + suffix;
  }
  return modifiedLabel;
}

export function pluralize(label, count) {
  label = count === 1 ? label : `${label}s`;
  return `${count} ${label}`;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function insertAfter(newNode, node) {
  node.parentNode.insertBefore(newNode, node.nextSibling);
}

export function insertBefore(newNode, node) {
  node.parentNode.insertBefore(newNode, node);
}

export function isEventMoreThanTwoWeeksAgo(start) {
  return start < new Date(new Date().setDate(new Date().getDate() - 14)).toISOString();
}

export function htmlFromString(string) {
  const div = document.createElement('div');
  div.innerHTML = string;
  return div.firstChild;
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

// open the specified url in a popup window, with optional
// umm, options
export function popupWindow(url, options = null) {
  const defaultOptions = {
    width: 640,
    height: 320,
    resizable: 'yes',
    scrollbars: 'no',
    toolbar: 'no',
    dependent: 'no',
    window_name: 'DependentWindow',
    location_bar: 'no',
    status_bar: 'no',
    align: 'topcenter',
  };

  const opts = {...defaultOptions, ...(options || {})};
  let left_position = 1;
  let top_position = 1;

  if (opts.align === 'center') {
    left_position = screen.width ? (screen.width - opts.width) / 2 : 0;
    top_position = screen.height ? (screen.height - opts.height) / 2 : 0;
  } else if (opts.align === 'topright') {
    left_position = screen.width ? screen.width - opts.width : 0;
    top_position = 1;
  } else if (opts.align === 'topcenter') {
    left_position = screen.width ? (screen.width - opts.width) / 2 : 0;
    top_position = 1;
  }

  const align_value = `top=${top_position},left=${left_position}`;
  const win = eval(
    `window.open(url, '${opts.window_name}','width=${opts.width},height=${opts.height},resizable=${opts.resizable},scrollbars=${opts.scrollbars},toolbar=${opts.toolbar},dependent=${opts.dependent},screenX=1,screenY=1,${align_value},location=${opts.location_bar},status=${opts.status_bar}')`
  );
  win.focus();
}

const MAX_EMAIL_LENGTH = 254;

/**
 * valid_email(email)
 *
 * - says whether the provided email address is valid or not, and if not,
 *   why
 * - returns an Object with three attributes:
 *   {
 *      is_valid: <boolean>,
 *      error_code: <string>
 *      error_message: <string>
 *   }
 *  - possible error codes (strings):
 *    - 'email_empty'
 *    - 'email_invalid'
 *    - 'email_too_long'
 *  - callers can use those error codes to provide their own,
 *    context-specific error messaging
 *
 * */
export function valid_email(email) {
  if (!email) {
    return {
      is_valid: false,
      error_code: 'email_empty',
      error_message: 'Email address is required',
    };
  }

  const email_invalid_message = 'The email address is invalid';
  const ret_email_invalid = {
    is_valid: false,
    error_code: 'email_invalid',
    error_message: email_invalid_message,
  };

  // W3C HTML5 Validation
  if (
    !/^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(
      email
    )
  ) {
    return ret_email_invalid;
  }

  // can't start with a dash
  if (/^-/i.test(email)) {
    return ret_email_invalid;
  }

  // require a tld
  if (email.split('@')[1].split('.').length < 2) {
    return ret_email_invalid;
  }

  if (email.length > MAX_EMAIL_LENGTH) {
    return {
      is_valid: false,
      error_code: 'email_too_long',
      error_message: `The email address is too long. Max length is ${MAX_EMAIL_LENGTH} characters.`,
    };
  }

  if (email.search(/\.\./g) != -1) {
    return {
      is_valid: false,
      error_code: 'two_consecutive_dots_not_allowed',
      error_message: `Two consecutive dots are not allowed in an email address.`,
    };
  }

  if (email.search(/.*\.@/g) != -1) {
    return {
      is_valid: false,
      error_code: 'cannot_end_with_period',
      error_message: `Email address cannot end with a dot.`,
    };
  }

  if (
    [
      'abuse@evite.com',
      'postmaster@evite.com',
      'hostmaster@evite.com',
      'webmaster@evite.com',
    ].includes(email.toLowerCase())
  ) {
    return {
      is_valid: false,
      error_code: 'not_valid_email_for_guest',
      error_message: `Email address cannot be used`,
    };
  }
  if ((email.match(/.*@.*\.com.com$/g) || []).length > 1) {
    return {
      is_valid: false,
      error_code: 'cannot_have_more_than_one_dot_com',
      error_message: `Email address cannot have more than one .com parts`,
    };
  }

  return {is_valid: true, error_code: '', error_message: ''};
}

/**
 * Single validation function for name/email
 *
 * @param field (string, 'name' or 'email')
 * @param value
 * @returns {string|null|string|string|*}
 */
export function validate_name_or_email(field, value) {
  if (field === 'name' && (!value || value.trim() === '')) {
    return 'Name is required';
  }
  if (field === 'email' && !valid_email(value).is_valid) {
    return valid_email(value).error_message;
  }

  return null;
}

/**
 * parse_us_phone_number(phone_number_text)
 *
 * (also works for canadian phone numbers)
 *
 * - pulls out a 10-digit number sequence (string) from the text entered,
 *   if possible
 * - if an 11-digit sequence is found and the first is a 1 (old school
 *   for "long distance"), the first digit (1) is discarded
 * - returns an Object with three attributes:
 *   {
 *      is_valid: <boolean>,
 *      formatted: <string>
 *      digits: <string>
 *   }
 *
 * Examples:
 * - parse_us_phone_number('  310.123-4567 ') returns:
 *   {
 *      "is_valid": true,
 *      "formatted": "(310) 123-4567",
 *      "digits": "3101234567"
 *   }
 * - parse_us_phone_number('3456 tom ugh') returns:
 *   {
 *      "is_valid": false,
 *      "formatted": "",
 *      "digits": "3456"
 *   }
 *
 * */
export function parse_us_phone_number(phone_number_text) {
  let digits = _get_digits_from(phone_number_text);
  const is_standard_length = digits.length === 10;
  const is_1_prefixed_standard_length = digits.length === 11 && digits.charAt(0) === '1';
  if (
    !(is_standard_length || is_1_prefixed_standard_length) ||
    phone_number_text.indexOf('@') > 0
  ) {
    return {
      is_valid: false,
      formatted: '',
      digits,
    };
  }
  if (is_1_prefixed_standard_length) {
    digits = digits.slice(1, 11); // toss the leading 1
  }
  // TODO should this also make sure a 10-digit digit sequence is a valid
  // phone number, or is that overkill?
  return {
    is_valid: true,
    formatted: _format_to_us_phone_number(digits),
    digits,
  };
}

export function normalize_phone_number(phone_number_string) {
  // Returns the original string if it doesn't validate
  const parsed = parse_us_phone_number(phone_number_string);
  if (parsed.is_valid) return `+1${parsed.digits}`;
  return phone_number_string;
}

// return just the digit characters from the given string
export function _get_digits_from(string_with_digits) {
  const allDigitChars = string_with_digits.match(/\d+/g) || [];
  return allDigitChars.join('');
}

export function pretty_format_phone_number(phone_digits_string) {
  // Returns a pretty formatted phone number, or the original if it is not a normalized phone number
  if (!phone_digits_string) return phone_digits_string;
  let digits = phone_digits_string.trim();
  if (digits[0] === '+') digits = digits.slice(1);
  if (digits[0] === '1' && digits.length === 11) digits = digits.slice(1);
  if (digits.match(/\D/) || digits.length !== 10)
    // Not a proper phone number, so return the original string
    return phone_digits_string;
  return _format_to_us_phone_number(digits);
}

// Ex: "3101234567" ==> "(310) 123-4567"
function _format_to_us_phone_number(digits) {
  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
}

export function getURLParameter(name) {
  // mocks
  const search = window.mock_location_search || window.location.search;
  const regex = new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`);
  const matches = regex.exec(search) || [null, ''];
  const data = decodeURIComponent(matches[1].replace(/\+/g, '%20'));
  if (data) return data;
  return null;
}

/**
 * checks if the url contains a certain string
 * @param url
 * @param str
 */
export function hasStringInUrl(url, str) {
  const urlArr = url.split('/');
  if (urlArr.length) {
    return urlArr.includes(str);
  }
  return false;
}

/**
 * stolen from the plugin jquery.parseParams
 *
 * @param query the url query (minus the ?)
 * @returns object of url parameters
 */
export function parseParams(query) {
  const re = /([^&=]+)=?([^&]*)/g;
  const decodeRE = /\+/g; // Regex for replacing addition symbol with a space
  function decode(str) {
    return decodeURIComponent(str.replace(decodeRE, ' '));
  }

  const params = {};
  let e;
  while ((e = re.exec(query))) {
    let k = decode(e[1]);
    const v = decode(e[2]);
    if (k.substring(k.length - 2) === '[]') {
      k = k.substring(0, k.length - 2);
    }
    (params[k] || (params[k] = [])).push(v);
  }
  for (const key in params) {
    if (params[key].length === 1) {
      params[key] = params[key][0];
    }
  }
  return params;
}

export function stringifyParams(params) {
  const paramList = [];
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      if (typeof params[key] === 'object') {
        for (let i = 0; i < params[key].length; ++i) {
          const value = encodeURIComponent(params[key][i]);
          paramList.push(`${key}=${value}`);
        }
      } else {
        const value = encodeURIComponent(params[key]);
        paramList.push(`${key}=${value}`);
      }
    }
  }
  paramList.sort();
  return Array.from(new Set(paramList).values()).join('&');
}

export const addParamsToUrl = (url, params) => {
  // adds the given parameters (string like "color=red&size=medium"
  // or object like {color: "red", size: "medium"}) and adds to the
  // given url (which can have params already or not)
  const paramsType = typeof params;
  switch (paramsType) {
    case 'string':
      if (!url.includes('?')) {
        url += '?';
      }

      const [path, currentQuery] = url.split('?');
      const paramsToAdd = currentQuery ? `${currentQuery}&${params}` : params;
      url = `${path}?${stringifyParams(parseParams(paramsToAdd))}`;
      break;

    case 'object':
      const keyNames = Object.keys(params);
      for (const idx in keyNames) {
        const key = keyNames[idx];
        const val = params[key] || '';
        url = addParamsToUrl(url, `${key}=${val}`);
      }
      break;

    default:
      throw "Unsupported data type! addParamsToUrl() only supports strings and objects for the 'params' argument.";
  }
  return url;
};

export function isFunction(value) {
  return typeof value === 'function' || value instanceof Function;
}

/**
 * server side expects offset timezones in formats like -0700
 *
 * * the sign must always be present
 * * server does not expect a : to separate minutes and hours
 *
 * @param date
 * @returns {string}
 */
export function formatTimezoneOffset(date = null) {
  date = date || new Date();

  // I had code to do this, but it seems with the native date object very difficult
  // to figure out the offset reliably given that getTimezoneOffset returns the local
  // offset no matter what
  date = new XDate(date);

  let result = date.toString('zzz');
  if (result === 'Z') {
    result = '+0000';
  }
  return result.replace(':', '');
}

export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function isStringEmptyOrAllWhitespace(inputString) {
  return !/\S/.test(inputString);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function areEqual(array, otherArray) {
  const s = new Set(array);
  return otherArray.length === array.length && otherArray.every((e) => s.has(e));
}

export function isValidURL(url_string) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    url_string
  );
}

export function httpsIfyUrl(url) {
  return url.replace(/^http:/, 'https:');
}

function pad(value) {
  return value < 10 ? `0${value}` : value;
}

function createUtcOffsetFromDate(date) {
  const sign = date.getTimezoneOffset() > 0 ? '-' : '+';
  const offset = Math.abs(date.getTimezoneOffset());
  const hours = pad(Math.floor(offset / 60));
  const minutes = pad(offset % 60);
  return `${sign + hours}:${minutes}`;
}

export function getTimezone() {
  const {TIMEZONES} = window;
  const localUtcOffset = createUtcOffsetFromDate(new Date());
  let timezoneId;
  let timezoneAbbr;

  for (let i = 0; i < TIMEZONES.length; i++) {
    const {utc_offset} = TIMEZONES[i];

    if (localUtcOffset === utc_offset) {
      timezoneId = TIMEZONES[i].timezone_id;
      timezoneAbbr = TIMEZONES[i].timezone_abbr;
      return {timezoneId, timezoneAbbr};
    }
  }
}

export const hasRegistry = (registriesData) =>
  !!(window.has_gift_registries || registriesData?.registry_urls?.length);

export function changePage(newPage) {
  // Change the current url
  history.push(newPage);

  // Tell Google Analytics about being on a new page
  dataLayer.push({event: 'pageview', page_title: 'drryl', page_path: newPage});
}

export function virtualPageview(newPage, pageTitle) {
  dataLayer.push({
    event: 'pageview',
    page_title: pageTitle,
    page_path: newPage,
  });
}

/**
 * This function recursively deletes a property from an object, no matter how deeply it's nested.
 *
 * @param {Object} obj - The object from which to delete the property.
 * @param {string} propertyName - The name of the property to delete.
 */
export function removeNestedProperty(obj, propertyName) {
  for (const prop in obj) {
    if (prop === propertyName) {
      delete obj[prop];
    } else if (typeof obj[prop] === 'object') {
      removeNestedProperty(obj[prop], propertyName);
    }
  }
}

/**
 * Checks if two arrays are equivalent
 * @param {Array} array1 - The first array
 * @param {Array} array2 - The second array
 * @returns boolean
 */
export const arraysAreEqual = () => (array1, array2) => {
  if (array1.length !== array2.length) return false;

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) return false;
  }

  return true;
};
